@import '@/assets/scss/_variables.scss';

.MonthRate_root {
    width: 25%;
    text-align: left;
}

.MonthRate_rate-box {
    --side: 20px; 
    background: rgba(#102032, 0.5);
    box-shadow: inset 0px 8px 10px rgba(34, 39, 47, 0.14);
    border-radius: 4px;
    width: var(--side);
    height: var(--side);
    position: relative;

    @media only screen and (min-width: $tablet-width) {
        --side: 26px;
    }

    span {
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(50%, -50%);
        line-height: 10px;
    }

    svg {
        --side: 10px;
        width: var(--side);
        height: var(--side);
        @media only screen and (min-width: $tablet-width) {
            --side: 14px;
        }
    }
}

.MonthRate_item {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #ffffff;
    padding-bottom: 10px;
    border-bottom: 1px dashed rgba(#2af3f3, 0.21);

    @media only screen and (min-width: $tablet-width) {
        font-size: 12px;
        line-height: 14px;
    }
    @media only screen and (min-width: $large-width) {
        font-size: 14px;
        line-height: 16px;
    }
}

.MonthRate_rate {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    @media only screen and (min-width: $tablet-width) {
        margin-top: 12px;
    }
}

.MonthRate_rate-value {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    margin-left: 6px;

    @media only screen and (min-width: $tablet-width) {
        font-size: 12px;
        line-height: 14px;
    }
    @media only screen and (min-width: $tablet-width) {
        font-size: 14px;
        line-height: 16px;
    }
}