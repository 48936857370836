@import '@/assets/scss/_variables.scss';

.Fund_block {
    background: rgba(#2d4d70, 0.55);
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
    border-radius: 4px;
}

.Fund_block-wrapper {
    padding: 20px;
}

.Fund_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;    

    @media only screen and (min-width: $tablet-width) {
        flex-wrap: nowrap;
        margin-bottom: 13px;
    }
}

.Fund_about {
    flex: 0 1 100%;
    margin: 0 0 20px;

    @media only screen and (min-width: $tablet-width) {
        flex: 0 1 calc(100% - 370px);
        margin: 0;
    }
}

.Fund_invest {
    flex: 100%;

    @media only screen and (min-width: $tablet-width) {
        flex: 0 1 350px;
    }
}