@import '@/assets/scss/_variables.scss';

.InvestStatistic_root {
    width: 100%;
    background: rgba(#2d4d70, 0.55);
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
    border-radius: 4px;
}

.InvestStatistic_wrapper {
    padding: 10px 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    @media only screen and (min-width: $tablet-width) {
        flex-wrap: nowrap;
        padding: 20px;
    }
}

.InvestStatistic_part {
    flex: 0 1 100%;
    margin: 0 0 20px;
    @media only screen and (min-width: $tablet-width) {
        flex: 0 1 calc(50% - 15px);
        margin: 0;
    }
    &:last-child {
        margin: 0;
    }
}

.InvestStatistic_block {
    display: flex;
}

.InvestStatistic_fund-assets {
    flex-basis: 100%;
    padding: 28px 30px;
    background: #5abc67;
    border-radius: 6px;
    background-image: url('../../../../../assets/img/_common/green-fake-chart.svg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (min-width: $tablet-width) {
        padding: 20px;
    }

    @media only screen and (min-width: $mobile-width) {
        flex-basis: 180px;
    }
}

.InvestStatistic_fund-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #fff;
    text-align: left;
    @media only screen and (min-width: $tablet-width) {
        font-size: 10px;
        line-height: 12px;
    }
}

.InvestStatistic_fund-amount {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    color: #fff;
    text-align: left;
    margin-top: 9px;
    white-space: nowrap;
    @media only screen and (min-width: $tablet-width) {
        margin-top: 6px;
        font-size: 21px;
        line-height: 25px;
    }
}

.InvestStatistic_septum {
    height: 1px;
    width: 100%;
    background: rgba(#2af3f3, 0.1);
    margin: 10px 0 10px;
    @media only screen and (min-width: $tablet-width) {
        margin: 20px 0 30px;
    }

    &--margin {
        margin: 10px 0 14px;
        @media only screen and (min-width: $tablet-width) {
            margin: 10px 0 25px;
        }
    }
}

.InvestStatistic_currency {
    background: rgba(#102032, 0.5);
    box-shadow: inset 0px 8px 10px rgba(34, 39, 47, 0.14);
    border-radius: 4px;
}

.InvestStatistic_currency-wrapper {
    padding: 6px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (min-width: $tablet-width) {
        padding: 16px 18px;
    }
}

.InvestStatistic_currency-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
        font-size: 10px;
        line-height: 12px;
        margin-left: 6px;
        @media only screen and (min-width: $tablet-width) {
            font-size: 14px;
            line-height: 16px;
        }
        @media only screen and (min-width: $large-width) {
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.InvestStatistic_currency-statistic {
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    @media only screen and (min-width: $tablet-width) {
        font-size: 14px;
        line-height: 16px;
    }
    @media only screen and (min-width: $large-width) {
        font-size: 16px;
        line-height: 19px;
    }    
    span {
        color: #5abc67;
    }
}

.InvestStatistic_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2af3f3;
    text-shadow: 0px 0px 10px #102032;
    text-align: left;
}

.InvestStatistic_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}