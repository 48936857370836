@import '@/assets/scss/_variables.scss';

.InvestWallet_root {
    flex-basis: 384px;
    background: rgba(hsl(180, 89%, 56%), 0.06);
    border-radius: 4px;
}

.InvestWallet_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    @media only screen and (min-width: $tablet-width) {
        flex-wrap: nowrap;
        padding: 10px 20px;
    }
}

.InvestWallet_amount_col {
    flex-basis: 100%;
    margin: 0 0 10px;
    @media only screen and (min-width: $tablet-width) {
        flex-basis: auto;
        margin: 0;
    }
}

.InvestWallet_amount-icon {
  color: #fff;
}

.InvestWallet_label {
    margin: 0 0 3px;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    text-align: center;
    @media only screen and (min-width: $tablet-width) {
        margin: 0;
        text-align: left;
    }
    @media only screen and (min-width: $large-width) {
        font-size: 14px;
        line-height: 16px;
    }
}

.InvestWallet_amount {
    font-size: 18px;
    line-height: 21px;
    color: #2af3f3;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: $tablet-width) {
        width: 100%;
        justify-content: flex-start;
    }

    svg {
        margin-right: 6px;
        vertical-align: middle;
    }
}

//.InvestWallet_btn {
//    width: 100%;
//    @media only screen and (min-width: $tablet-width) {
//        width: 124px;
//    }
//
//    &.Button_defaultBtn.Button_large {
//        line-height: 36px;
//    }
//}