@import '@/assets/scss/_variables.scss';

.AboutFund_root {
    background: rgba(#2d4d70, 0.55);
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
    border-radius: 4px;
}

.AboutFund_wrapper {
    padding: 14px 10px 10px;
    @media only screen and (min-width: $tablet-width) {
        padding: 20px;
    }
}

.AboutFund_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2af3f3;
    text-align: left;
    text-shadow: 0px 0px 10px #102032;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#2af3f3, 0.1);
}

.AboutFund_container {
    padding: 10px 0 0;
    @media only screen and (min-width: $tablet-width) {
        padding: 16px 0;
    }
}

.AboutFund_description {
    font-size: 12px;
    line-height: 17px;
    color: #a6aebf;
    text-align: left;
    margin: 0 0 4px;

    @media only screen and (min-width: $tablet-width) {
        margin: 0;
    }
    @media only screen and (min-width: $large-width) {
        font-size: 14px;
    }
}

.AboutFund_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    padding: 5px 0;
    min-height: 38px;
    border-bottom: 1px dashed rgba(#2af3f3, 0.14);

    @media only screen and (min-width: $tablet-width) {
        font-size: 14px;
        line-height: 16px;
        min-height: 40px;
    }
    @media only screen and (min-width: $large-width) {
        font-size: 16px;
        line-height: 19px;
    }

    &:last-of-type {
        border: none;
    }    
}

.AboutFund_leftPart {
    font-weight: 500;
    text-transform: uppercase;
    text-align: left;
    margin-right: 16px;
}

.AboutFund_rightPart {
    text-align: right;
}