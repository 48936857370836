@import '@/assets/scss/_variables.scss';

.StartInvest_root {
    background: rgba(#2d4d70, 0.55);
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
    border-radius: 4px;
}

.StartInvest_wrapper {
    padding: 14px 10px 20px;
    @media only screen and (min-width: $tablet-width) {
        padding: 20px;
    }
}

.StartInvest_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #2af3f3;
    text-align: left;
    text-shadow: 0px 0px 10px #102032;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(#2af3f3, 0.1);
}

.StartInvest_container {
    padding: 25px 0 0;
    @media only screen and (min-width: $tablet-width) {
        padding: 35px 0 0;
    }
}

.StartInvest_date-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #fff;
    margin: 0 0 18px;

    @media only screen and (min-width: $tablet-width) {
        font-size: 16px;
        line-height: 19px;
    }
    @media only screen and (min-width: $large-width) {
        font-size: 18px;
        line-height: 21px;
    }

    .StartInvest_leftPart {
        padding: 0 23px 0 0;
        border-right: 1px solid rgba(#a6aebf, 0.55);

        @media only screen and (min-width: $tablet-width) {
            padding: 0 14px 0 0;
        }
    }

    .StartInvest_rightPart {
        color: #2af3f3;
        padding: 0 0 0 20px;

        @media only screen and (min-width: $tablet-width) {
            padding: 0 0 0 14px;
        }
    }
}

.StartInvest_form {
    border-bottom: 1px dashed rgba(#2af3f3, 0.2);
    margin: 22px 0 20px;

    @media only screen and (min-width: $tablet-width) {
        margin: 0 0 17px;
    }
}

.StartInvest_form-element {
    margin-bottom: 14px;

    .v-input {
        font-size: 14px;
        line-height: 16px;
    }
}

.StartInvest_checkbox-label {
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
     @media only screen and (min-width: $large-width) {
        font-size: 14px;
    }
}

.StartInvest_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;

    .StartInvest_amount {
        color: #2af3f3;
    }
}