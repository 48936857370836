@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';




































































































.TransferModal_Title {
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  margin: 0 0 28px 0;
}

.TransferModal_Wallets {
  padding: 0 0 0 62px;
  position: relative;
}

.TransferModal_Wallet {
  margin: 0 0 20px 0;
  label {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
  }
  .v-select__selection--comma {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}

.TransferModal_Amount {
  .text-field-input-root .TextFieldInput_label label {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.04em;
  }
}

.TransferModal_Swap {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  img:nth-child(3) {
    transform: rotate(180deg);
  }
  &Icon {
    width: 36px;
    height: 36px;
    background: #2af3f3;
    border-radius: 1.6px;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &Bg {
      width: 24px;
      height: 24px;
      background: #2d4d70;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transform: rotate(90deg);
      }
    }
  }
}

.TransferModal_Available {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
}

.TransferModal_Buttons {
  display: flex;
  margin: 20px 0 0 0;
  button {
    //height: 46px;
    width: 50%;
    //font-size: 12px;
    &:first-child {
      margin: 0 5px 0 0;
    }
    &:last-child {
      margin: 0 0 0 5px;
    }
  }
}
